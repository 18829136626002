import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import Metadata from "../components/metadata"

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "beach-sunset" }) {
        childCloudinaryAsset {
          fluid {
            ...CloudinaryAssetFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <Metadata title="Home" description="This is my home page" />
      <div>
        <h1>Home page</h1>
        <h2>Lorem ipsum dolorem.</h2>
        <Image fluid={data.file.childCloudinaryAsset.fluid} alt="banner" />
      </div>
    </Layout>
  )
}

export default Index